import { manifestLoader } from '@vision6/microfrontend-manifest-library';

import Http_Client from './services/http_client';
import manifest from '../manifest.json';

if (typeof window.manifest_configs === 'object' && window.manifest_configs.manifest_json_url) {
    manifestLoader(() => {
        return Http_Client.get(window.manifest_configs.manifest_json_url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // TODO: Error management... Let's show an error message when this fails
            });
    });
} else {
    manifestLoader(() => {
        return new Promise((resolve) => {
            resolve(manifest);
        });
    });
}