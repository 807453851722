
// Steps
export const STEP_ERROR                 = "error";
export const STEP_LOGIN                 = "login";
export const STEP_RESUME                = "resume";
export const STEP_SELECT_ACCOUNT        = "select_account";
export const STEP_SELECT_SCOPE          = "select_scope";
export const STEP_ALLOWED               = "allowed";
export const STEP_LOGGED_OUT            = "logged_out";
export const STEP_INVITATION_LOGIN      = "invitation_login";
export const STEP_TRIAL_SIGNUP_LOGIN    = "trial_signup_login";
export const STEP_EMAIL_MFA             = "email_mfa";
export const STEP_OATH_MFA              = "oath_mfa";
export const STEP_SMS_MFA               = "sms_mfa";
export const STEP_BACKUP_MFA            = "backup_mfa";
export const STEP_RECOVERY_MFA          = "recovery_mfa";
export const STEP_TRY_ANOTHER_MFA       = "try_another_mfa";
export const STEP_SETUP_AUTHENTICATION  = "setup_authentication";
export const STEP_REVIEW_2FA_SETTINGS   = "review_mfa_settings";

// Auth Types
export const AUTH_OATH       = 0x01;
export const AUTH_SMS        = 0x02;
export const AUTH_BACKUP     = 0x08;
export const AUTH_RECOVERY   = 0x10;
export const AUTH_EMAIL      = 0x20;

export const LOGIN_ERROR_CODES      = {
    0: "login_success",
    1: "login_expired",
    2: "login_security",
    3: "login_invalid",
    4: "login_user_logout",
    5: "login_fatal",
    6: "login_modify_account",
    7: "login_deactivated",
    8: "login_deleted",
    9: "login_admin_logout",
    10: "login_account_expired",
    11: "login_kickout",
    12: "login_no_javascript",
    13: "login_bad_habit",
    14: "login_confirm_ip",
    15: "login_restricted",
    16: "login_ip_restricted",
    17: "login_too_many_invalid",
    18: "login_requires_2fa",
    19: "login_profile_invalid",
    20: "login_use_profile",
    21: "login_profile_password_invalid",
    22: "login_profile_email_invalid",
    23: "login_add_user_to_profile",
    24: "login_to_enable_email_login",
    25: "login_confirm_email_address",
    26: "profile_has_many_trial_accounts",
    27: "login_profile_session_logout",
    28: "password_reset_request_sent",
    29: "password_reset_successful",
    30: "password_reset_request_expired",
    32: "login_to_confirm_email"
};

export const ALERT_SUCCESS          = [ '4', '25', '27', '28', '29' ];