const configs = {};

configs.set = function(options) {

    // Environment
    configs.debug = options.debug === true;

    // APP pathing and location
    configs.body_root_element_id    = options.body_root_element_id  || 'root';
    configs.basename                = options.basename              || '/';
    configs.public_path             = options.public_path           || '';
    configs.api_url                 = options.frontend_api_url      || '';

    // Locale
    configs.locale                  = options.locale    || 'en-au';
    configs.timezone                = options.timezone  || 'Australia/Brisbane';

    // Workflow data
    if (typeof options.initial_step_data === 'undefined') {
        options.initial_step_data = {};
    }
    configs.password_reset_url      = options.password_reset_url                || '';
    configs.profile_edit_url        = options.profile_edit_url                  || '';
    configs.signup_url              = options.signup_url                        || '';
    configs.client_name             = options.initial_step_data.client_name     || '';
    configs.client_logo_uri         = options.initial_step_data.client_logo_uri || '';
    configs.initial_step_data       = options.initial_step_data                 || { step: 'failure' };
}

export default configs;