import axios from 'axios';

import Configs from 'root/configs';
import * as Constants from 'root/constants';

export function updateRequestToken(new_request_token) {
    axios.defaults.headers.common['Request-Token'] = new_request_token;
}

// If the request-token has been included in the initial_step_data, add it to all headers.
if (Configs.initial_step_data && Configs.initial_step_data.request_token) {
    updateRequestToken(Configs.initial_step_data.request_token);
}

axios.interceptors.request.use(
    request => {
        return request;
    },
    error => {
        if (axios.isCancel(error)) {
            return;
        }

        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        // If the login has expired, save the new Request-Token to Configs and update the default
        if (typeof response.data.login_error_code == 'string' && typeof response.data.request_token == 'string' && response.data.login_error_code == Constants.LOGIN_ERROR_CODES[1]) {
            // Update stored request_token
            Configs.initial_step_data.request_token = response.data.request_token;

            // Update default header for all following requests
            updateRequestToken(response.data.request_token);
        }

        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axios;